$(function () {
  /* $('.play-button').on('click', function () {
    $(this).hide();
    $('.home-video').trigger('play');
    $('.home-video').attr('controls', '');
  }); */

  const slideCaptions = [
    'งานสัมมนาครั้งที่ 1 - วันศุกร์ที่ 13 ธันวาคม 2562 ห้องวิภาวดี Ballroom โรงแรม เซ็นทาราแกรนด์เซ็นทรัลพลาซาลาดพร้าว กรุงเทพฯ',
    'งานสัมมนาครั้งที่ 2 - วันศุกร์ที่ 13 ธันวาคม 2562 ห้องวิภาวดี Ballroom โรงแรม เซ็นทาราแกรนด์เซ็นทรัลพลาซาลาดพร้าว กรุงเทพฯ',
    'งานสัมมนาครั้งที่ 3 - วันพุธที่ 9 กันยายน 2563 ห้อง World Ballroom B ชั้น 23 โรงแรม เซ็นทาราแกรนด์ แอท เซ็นทรัลเวิลด์ กรุงเทพฯ',
  ];

  $('.home-slide-caption').html(slideCaptions[0]);
  $('.home-slider').on('slide.bs.carousel', function (evt) {
    var step = $(evt.relatedTarget).index();
    console.log('> step =', step);
    $('.home-slide-caption').html(slideCaptions[step]);
  });

  $('.video-home-button').on('click', function () {
    $(this).hide();
    $('.home-video').trigger('play');
    $('.home-video').attr('controls', '');
  });

  const dataCategories = [
    {
      id: 1,
      name: 'เศรษฐกิจ',
      subcategories: [
        { id: 1, name: 'ดัชนีเศรษฐกิจ', docIds: [1, 2, 23, 24, 25, 26, 28, 32, 56] },
        { id: 2, name: 'ดัชนีอุตสาหกรรม', docIds: [3, 5, 7] },
        { id: 3, name: 'ประชากร', docIds: [4, 57] },
        { id: 4, name: 'ภาคขนส่ง', docIds: [6, 17, 18, 19, 20, 21, 22, 84] },
        { id: 5, name: 'อัตราแลกเปลี่ยน', docIds: [51, 55, 132] },
      ],
    },
    {
      id: 2,
      name: 'พลังงาน',
      subcategories: [
        {
          id: 1,
          name: 'การใช้',
          docIds: [8, 9, 10, 11, 12, 13, 15, 16, 27, 37, 38, 39, 40, 71, 72, 73, 74, 80, 81, 82, 83, 95, 96, 97, 98, 99, 100, 103, 104, 105, 116, 120, 122],
        },
        { id: 2, name: 'การผลิต', docIds: [30, 66, 75, 85, 86, 87, 92, 114, 128, 131, 133, 134, 135, 137, 139, 141, 143, 145] },
        { id: 3, name: 'การผลิตและการใช้', docIds: [14, 62, 63] },
        { id: 4, name: 'สำรอง', docIds: [29] },
        { id: 5, name: 'Capacity', docIds: [31, 64, 65, 117, 118, 119] },
        { id: 6, name: 'Emission', docIds: [33, 34, 35, 36] },
        { id: 7, name: 'ราคา', docIds: [42, 43, 44, 45, 46, 47, 48, 49, 50, 52, 53, 54, 124, 125, 126, 127] },
        { id: 8, name: 'Peak', docIds: [58, 59, 115] },
        { id: 9, name: 'Master', docIds: [60, 61, 136, 138, 140, 142, 144] },
        { id: 10, name: 'การนำเข้า', docIds: [67, 76, 106, 107, 108, 109, 110] },
        { id: 11, name: 'การส่งออก', docIds: [68, 77, 111, 112, 121] },
        {
          id: 12,
          name: 'Stock',
          docIds: [
            1,
            2,
            3,
            4,
            5,
            6,
            7,
            8,
            9,
            10,
            11,
            12,
            13,
            14,
            15,
            16,
            17,
            18,
            19,
            20,
            21,
            22,
            23,
            24,
            25,
            26,
            27,
            28,
            29,
            30,
            31,
            32,
            33,
            34,
            35,
            36,
            37,
            38,
            39,
            40,
            41,
            42,
            43,
            44,
            45,
            46,
            47,
            48,
            49,
            50,
            51,
            52,
            53,
            54,
            55,
            56,
            57,
            58,
            59,
            60,
            61,
            62,
            63,
            64,
            65,
            66,
            67,
            68,
            69,
            70,
            71,
            72,
            73,
            74,
            75,
            76,
            77,
            78,
            79,
            80,
            81,
            82,
            83,
            84,
            85,
            86,
            87,
            88,
            89,
            90,
            91,
            92,
            93,
            94,
            95,
            96,
            97,
            98,
            99,
            100,
            101,
            102,
            103,
            104,
            105,
            106,
            107,
            108,
            109,
            110,
            111,
            112,
            113,
            114,
            115,
            116,
            117,
            118,
            119,
            120,
            121,
            122,
            123,
            124,
            125,
            126,
            127,
            128,
            129,
            130,
            131,
            132,
            133,
            134,
            135,
            136,
            137,
            138,
            139,
            140,
            141,
            142,
            143,
            144,
            145,
            146,
            147,
            148,
            149,
            150,
            151,
            152,
            153,
            154,
            155,
            156,
            157,
            158,
            159,
            160,
            161,
            162,
            163,
            164,
            165,
            166,
            167,
            168,
            169,
            170,
            171,
            172,
            173,
            174,
            175,
            176,
            177,
            178,
            179,
            180,
            181,
            182,
            183,
            184,
            185,
            186,
            187,
            188,
            189,
            190,
            191,
            192,
            193,
            194,
            195,
            196,
            197,
            198,
            199,
            200,
          ],
        },
        { id: 13, name: 'การแปรรูป', docIds: [70, 79, 88, 89, 90, 91, 93, 94, 101, 102, 129, 130] },
        { id: 14, name: 'ภาษีและกองทุน', docIds: [123] },
      ],
    },
    {
      id: 3,
      name: 'รายงานสถิติ',
      subcategories: [
        { id: 1, name: 'ภาพรวมสถิติพลังงาน', docIds: [146, 147, 148] },
        {
          id: 2,
          name: 'ดัชนีพลังงาน',
          docIds: [
            149,
            150,
            151,
            152,
            153,
            154,
            155,
            156,
            157,
            158,
            159,
            160,
            161,
            162,
            163,
            164,
            165,
            166,
            167,
            168,
            169,
            170,
            171,
            172,
            173,
            174,
            175,
            176,
            177,
            178,
            179,
            180,
            181,
            182,
            183,
            184,
            185,
            186,
            187,
            188,
            189,
            190,
            191,
            192,
            193,
            194,
            195,
            196,
            197,
            198,
            199,
            200,
          ],
        },
      ],
    },
  ];

  //
  var catId = 0;
  var subcatId = 0;
  var subcategories = [];

  $('#data-category-select').on('change', function (e) {
    catId = parseInt(e.target.value);
    $('#data-subcategory-select').attr('hidden', catId === 0);
    if (catId === 0) {
      $('.data-table > tbody > tr').attr('hidden', false);
      return;
    }

    const subcategories = dataCategories.filter((cat) => cat.id === catId).map((cat) => cat.subcategories)[0];
    $('#data-subcategory-select').empty();
    subcategories.map((subcat) => {
      $('#data-subcategory-select').append(`<option value="${subcat.id}">${subcat.name}</option>`);
    });
    subcatId = 1;
    refreshDataTable();
  });

  $('#data-subcategory-select').on('change', function (e) {
    subcatId = parseInt(e.target.value);
    refreshDataTable();
  });

  function refreshDataTable() {
    subcategories = dataCategories.filter((cat) => cat.id === catId).map((cat) => cat.subcategories)[0];
    const docIds = subcategories[subcatId - 1].docIds;

    $('.data-table > tbody > tr').attr('hidden', true);
    docIds.map((id) => {
      $('.data-table > tbody > tr')
        .eq(id - 1)
        .attr('hidden', false);
    });
  }
});
